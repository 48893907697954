import '../styles/CreateCommunity.css';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useUser } from '../contexts/UserProvider';
import { useModal } from '../contexts/ModalProvider';
import query from '../utils/query';
import Input from './Input';
import Button from './Button';

function CreateCommunity() {
    const user = useUser();
    const { closeModal } = useModal();
    const [createText, setCreateText] = useState('');

    const communitiesMutation = useMutation({
        mutationFn: community => query('/communities', {
            method: 'POST', body: { community }
        }),
        onSuccess: addCommunity
    });


    function handleCreateTextChange(event) {
        const newCreateText = event.target.value;
        setCreateText(newCreateText);
    }

    function handleCreateCommunity() {
        const community = { name: createText };
        communitiesMutation.mutate(community);
        closeModal();
    }

    function addCommunity(community) {
        user.setCommunities((oldCommunities = []) => {
            const communityIsInCache = oldCommunities
                .some(c => c.id === community.id);

            return communityIsInCache
                ? oldCommunities
                : [community, ...oldCommunities];
        });
        user.setSelectedCommunity(community);
    }

    return <div className='create-community'>
        <div className='create-community__label'>
            Create a community with just a name, like <span>Burger</span>.
        </div>
        <div className='create-community__controls'>
            <Input
                placeholder='Enter a community name...'
                maxLength='40'
                value={createText}
                autoFocus={true}
                onChange={handleCreateTextChange}
            />
            <Button
                content='Create'
                disabled={!createText}
                onClick={handleCreateCommunity}
            />
        </div>
    </div>
}

export default CreateCommunity;