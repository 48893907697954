import '../styles/MessageBox.css';
import { useState, useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { ulid } from 'ulid';
import TextareaAutosize from 'react-textarea-autosize';
import { ReactComponent as DeleteIcon } from '../assets/delete.svg';
import { ReactComponent as UploadFileIcon } from '../assets/upload-file.svg';
import { ReactComponent as SendIcon } from '../assets/send.svg';
import query from '../utils/query';
import getIsTouchOnlyDevice from '../utils/getIsTouchOnlyDevice';
import { useUser } from '../contexts/UserProvider';
import { useModal } from '../contexts/ModalProvider';
import useEvent from '../hooks/useEvent';
import Button from './Button';

function MessageBox({ channel, receivedMessage, updateMessages }) {
    const user = useUser();
    const { openModal } = useModal();
    const [files, setFiles] = useState([]);
    const [text, setText] = useState('');
    const [throttling, setThrottling] = useState(0);
    let textareaRef = useRef();
    const isTouchOnlyDevice = getIsTouchOnlyDevice();
    const trimmedText = text.trim();

    useEvent('drop', event => {
        event.preventDefault();
        const droppedItems = event.dataTransfer.files;
        const newFiles = [...droppedItems].filter(item =>
            item.type.startsWith('image/')
        );
        addFiles(newFiles);
    });

    useEvent('dragover', event => {
        event.preventDefault();
    });

    useEvent('paste', event => {
        const clipboardItems = event.clipboardData.items;
        const newFiles = [...clipboardItems].filter(item =>
            item.type.startsWith('image/')
        ).map(item => item.getAsFile());
        addFiles(newFiles);
    });

    const messagesMutation = useMutation({
        mutationFn: message => query(`/channels/${channel.id}/messages`, {
            method: 'POST',
            body: message,
            isMultipart: true
        })
    });

    const typingMutation = useMutation({
        mutationFn: typing => query(`/channels/${channel.id}/typing`, {
            method: 'POST', body: { typing }
        })
    });

    function handleDeleteFile(selectedIndex) {
        textareaRef.focus();

        const newFiles = [...files].filter((file, index) =>
            index !== selectedIndex
        );

        setFiles(newFiles);
    }

    function handleTextChange(event) {
        setText(event.target.value);
        initiateTyping();
    }

    function handleSendMessageEnter(event) {
        event.stopPropagation();
        if (event.key !== 'Enter' || event.shiftKey) return;
        if (isTouchOnlyDevice) return;
        event.preventDefault();
        createMessage();
    }

    function handleSendMessageButton() {
        textareaRef.focus();
        createMessage();
    }

    function handleFileUploadChange(event) {
        textareaRef.focus();
        const newFiles = [...event.target.files];
        event.target.value = '';
        addFiles(newFiles);
    }

    function addFiles(newFiles) {
        if (!newFiles.length) return;

        newFiles = newFiles.map(file => {
            const fileURL = URL.createObjectURL(file);
            return [file, fileURL];
        });

        const isInvalidFileSize = getIsInvalidFileSize(newFiles);
        if (isInvalidFileSize) return;

        setFiles([...files, ...newFiles]);
    }

    function getIsInvalidFileSize(selectedFiles) {
        const filesSizeReducer = (a, b) => a + b[0].size;
        const filesSize = files.reduce(filesSizeReducer, 0);
        const selectedFilesSize = selectedFiles.reduce(filesSizeReducer, 0);
        const totalFilesSize = filesSize + selectedFilesSize;
        const maxFilesSize = 15_728_640;

        if (totalFilesSize > maxFilesSize) {
            openModal('INVALID_FILE_SIZE');
            return true;
        }
    }

    function initiateTyping() {
        const timestamp = Date.now();
        if (throttling + 2000 > timestamp) return;

        typingMutation.mutate();
        setThrottling(timestamp);
    }

    function createMessage() {
        const content = trimmedText;
        if (!content.match(/./) && !files.length) return;

        setText('');
        setFiles([]);

        const nonce = ulid();
        const formData = new FormData();
        formData.append('content', content);
        formData.append('nonce', nonce);

        for (const file of files) {
            formData.append('file', file[0]);
        }

        const message = formData;
        const unsentMessage = {
            content, files, user,
            channel_id: channel.id,
            nonce, isUnsent: true
        };

        messagesMutation.mutate(message);
        updateMessages(unsentMessage);
        receivedMessage.current = message;
        setThrottling(0);
    }

    return <div className='message-box'>
        {files[0] && <div className='message-box__images-border'>
            <div className='message-box__images'>
                {files.map(([file, fileURL], index) => {
                    const { type, name } = file;

                    return <div
                        key={index}
                        className='message-box__image'
                    >
                        <div className='message-box__image-header'>
                            <span>{name}</span>
                            <Button
                                content={<DeleteIcon />}
                                tooltip='Delete'
                                onClick={() => handleDeleteFile(index)}
                            />
                        </div>
                        <div className='message-box__image-body'>
                            <embed
                                className='message-box__image-file'
                                src={fileURL}
                                type={type}
                                alt={name}
                                draggable={false}
                            />
                        </div>
                    </div>
                })}
            </div>
        </div>}
        <div className='message-box__input-send'>
            <div className='message-box__input'>
                <TextareaAutosize
                    className='message-box__textarea'
                    ref={ref => textareaRef = ref}
                    placeholder={`Send message in ${channel.name}...`}
                    maxRows='15'
                    maxLength='5000'
                    value={text}
                    autoFocus={!isTouchOnlyDevice}
                    onChange={handleTextChange}
                    onKeyDown={handleSendMessageEnter}
                />
                <label className='message-box__upload'>
                    <input
                        className='message-box__upload-input'
                        type='file'
                        accept='image/*'
                        multiple={true}
                        onChange={handleFileUploadChange}
                    />
                    <UploadFileIcon />
                </label>
            </div>
            <button
                className='message-box__send'
                disabled={!trimmedText && !files.length}
                onClick={handleSendMessageButton}
            >
                <SendIcon />
            </button>
        </div>
    </div>
}

export default MessageBox;