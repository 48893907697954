import '../styles/Button.css';
import { ReactComponent as LoadingIcon } from '../assets/loading.svg';
import Tooltip from './Tooltip';

function Button({ content, model = 'primary', tooltip,
    placement, styles = {}, isSelected, isLoading,
    type = 'button', disabled, onClick }) {
    const isString = typeof content === 'string';

    let classNames = `button button--${model}`;
    if (isSelected) classNames += ' button--selected';
    if (isLoading) classNames += ' button--loading';
    if (isString) classNames += ' button--string';

    return <Tooltip
        content={tooltip}
        placement={placement}
        disabled={!tooltip}
    >
        <button
            className={classNames}
            style={styles.button}
            type={type}
            disabled={disabled || isLoading}
            onClick={onClick}
        >
            <div
                className='button__content'
                style={styles.content}
            >
                {content}
            </div>
            <div className='button__loading'>
                <LoadingIcon />
            </div>
        </button>
    </Tooltip>
}

export default Button;