import '../styles/Toggle.css';
import { useId } from 'react';
import ReactToggle from 'react-toggle';

function Toggle({ label, description, isChecked, setIsChecked, disabled }) {
    const toggleId = useId();

    function handleChange(event) {
        const newIsChecked = event.target.checked;
        setIsChecked(newIsChecked);
    }

    return <div className='toggle'>
        <label
            className='toggle__label'
            htmlFor={toggleId}
        >
            {label}
            <span className='toggle__description'>
                {description}
            </span>
        </label>
        <ReactToggle
            id={toggleId}
            checked={isChecked}
            onChange={handleChange}
            disabled={disabled}
        />
    </div>
};

export default Toggle;