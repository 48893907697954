import '../styles/Input.css';

function Input({ id, style, type = 'text', placeholder,
    maxLength, autoFocus, autoComplete = 'on', readOnly,
    disabled, value, onChange, onFocus, onBlur }) {
    return <input
        className='input'
        id={id}
        style={style}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        autoFocus={autoFocus}
        autoComplete={autoComplete}
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
    />
}

export default Input;