import { ReactComponent as OverviewIcon } from '../assets/overview.svg';
import { ReactComponent as UpdatesIcon } from '../assets/updates.svg';
import { ReactComponent as CakeIcon } from '../assets/cake.svg';
import { ReactComponent as ProfileIcon } from '../assets/profile.svg';
import { ReactComponent as AccountIcon } from '../assets/account.svg';
import { ReactComponent as AccessibilityIcon } from '../assets/accessibility.svg';
import { ReactComponent as AppearanceIcon } from '../assets/appearance.svg';
import { ReactComponent as HomeIcon } from '../assets/home.svg';
import { ReactComponent as SettingsIcon } from '../assets/settings.svg';
import { useUser } from '../contexts/UserProvider';
import Overview from './../components/Overview';
import Updates from './../components/Updates';
import Recipe from './../components/Recipe';
import ProfileSettings from './../components/ProfileSettings';
import AccountSettings from '../components/AccountSettings';
import AccessibilitySettings from './../components/AccessibilitySettings';
import AppearanceSettings from './../components/AppearanceSettings';

function useSectionTypes() {
    const { selectedSectionId } = useUser();

    const homeTabs = [
        { icon: <OverviewIcon />, name: 'Overview', component: <Overview /> },
        { icon: <UpdatesIcon />, name: 'Updates', component: <Updates /> },
        { icon: <CakeIcon />, name: 'Oogat Booga', component: <Recipe /> }
    ];

    const settingsTabs = [
        { icon: <ProfileIcon />, name: 'Profile', component: <ProfileSettings /> },
        { icon: <AccountIcon />, name: 'Account', component: <AccountSettings /> },
        { icon: <AccessibilityIcon />, name: 'Accessibility', component: <AccessibilitySettings /> },
        { icon: <AppearanceIcon />, name: 'Appearance', component: <AppearanceSettings /> },
    ];

    const sectionTypes = [
        { icon: <HomeIcon />, name: 'Home', tabs: homeTabs },
        { icon: <SettingsIcon />, name: 'Settings', tabs: settingsTabs }
    ];

    const selectedSectionType = sectionTypes[selectedSectionId];

    return { sectionTypes, selectedSectionType };
}

export default useSectionTypes;