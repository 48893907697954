import '../styles/Navbar.css';
import { motion } from 'framer-motion';
import { ReactComponent as Logo } from '../assets/logo.svg';
import config from '../config.json';

function Navbar() {
  return <nav className='navbar'>
    <motion.div
      className='navbar__logo'
      initial={{ x: '-200px', opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 1, type: 'spring' }}
    >
      <Logo />
    </motion.div>
    <motion.div
      className='navbar__name'
      initial={{ x: '100vw', opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 1, type: 'spring' }}
    >
      <span>chat</span>pex
      {!config.IS_PRODUCTION && <span
        style={{ color: 'var(--error-color)' }}
      >
        &nbsp;dev
      </span>}
    </motion.div>
  </nav>
}

export default Navbar;