import '../styles/ErrorMessage.css';
import { motion } from 'framer-motion';
import { ReactComponent as ErrorIcon } from '../assets/error.svg';

const ERROR_MESSAGES = {
    'USER-RATE-LIMITED':
        `You're being too fast! Please slow down.`,

    'USER-NAME-INVALID-CHARACTERS':
        'Your username can only contain spaces, dashes, and alphanumeric characters!',
    'USER-NAME-INVALID-LENGTH':
        `Your username must be between 1-40 characters long.`,
    'USER-NAME-ALREADY-TAKEN':
        `That username is already taken! Please choose another.`,

    'USER-EMAIL-ALREADY-TAKEN':
        `That email address is already registered with another account!`,
    'USER-EMAIL-INVALID-MATCH':
        `Your email address doesn't seem to be real. Please check it!`,
    'USER-EMAIL-NOT-FOUND':
        `That's not a registered email! Try again...`,

    'USER-PASSWORD-INVALID':
        `That password's incorrect. You get an F.`,
    'USER-PASSWORD-INVALID-LENGTH':
        `Your password must be 8-30 characters long.`,

    'USER-COLOR-INVALID-MATCH':
        `Your color's hex code should be 7 characters long, including the hashtag.`,

    'USER-AVATAR-INVALID-TYPE':
        `Your avatar should be an image of type jpg, png, svg, or gif.`,
    'USER-AVATAR-INVALID-SIZE':
        `Your avatar must be 15 MB or less in size.`,

    'COMMUNITY-INVITE-NOT-FOUND':
        `That community invite doesn't exist. Maybe it did before.`,
};

function ErrorMessage({ error, message }) {
    return (message || error) && <motion.div
        className='error-message'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
    >
        <ErrorIcon />
        {message || ERROR_MESSAGES[error] || 'An unknown error occurred.'}
    </motion.div>
}

export default ErrorMessage;