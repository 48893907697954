import '../styles/AddCommunity.css';
import { useModal } from './../contexts/ModalProvider';
import Button from './Button';

function AddCommunity() {
    const { advanceModal } = useModal();

    function handleJoinCommunity() {
        advanceModal('JOIN_COMMUNITY');
    }

    function handleCreateCommunity() {
        advanceModal('CREATE_COMMUNITY');
    }

    return <div className='add-community'>
        <Button
            content='Join an existing community'
            onClick={handleJoinCommunity}
        />
        <span>OR</span>
        <Button
            content='Create one from scratch'
            onClick={handleCreateCommunity}
        />
    </div>
}

export default AddCommunity;