import '../styles/Lobby.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import notificationSound from '../assets/sounds/notification.mp3';
import getIsTouchOnlyDevice from './../utils/getIsTouchOnlyDevice';
import { useUser } from './../contexts/UserProvider';
import useEvent from './../hooks/useEvent';
import LogIn from './LogIn';
import SignUp from './SignUp';
import Panels from './Panels';
import Warning from './Warning';
import Screen from './Screen';
import Modal from './Modal';

function Lobby() {
    const user = useUser();
    const isTouchOnlyDevice = getIsTouchOnlyDevice();

    useEvent('message', event => {
        if (event.origin !== window.location.origin) return;

        if (!event.data) {
            throw new Error('No data was sent with the message...');
        }

        switch (event.data.type) {
            case 'PLAY_NOTIFICATION_SOUND': playNotificationSound(); break;
        }
    }, navigator.serviceWorker);

    function playNotificationSound() {
        const sound = new Audio(notificationSound);
        sound.play().catch(() => console.log('Could not play sound...'));
    }

    let classNames = 'lobby__border';
    if (isTouchOnlyDevice) classNames += ' touch-only-device';
    if (user.settings['FULLSCREEN-MODE']) classNames += ' fullscreen';
    classNames += user.settings['LIGHT-MODE'] ? ' light-mode' : ' dark-mode';

    const authenticationRoutes = user.isLoggedIn
        ? <>
            <Route exact path='/login' element={<Navigate to='/app' />} />
            <Route exact path='/signup' element={<Navigate to='/app' />} />
        </>
        : <Route exact path='/app' element={<Navigate to='/login' />} />

    return <motion.div
        className={classNames}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1, type: 'spring' }}
    >
        <div className='lobby'>
            {user.isSuccess && <Routes>
                {authenticationRoutes}
                <Route exact path='/login' element={<LogIn />} />
                <Route exact path='/signup' element={<SignUp />} />
                <Route exact path='/app' element={<Panels />} />
                <Route path='*' element={<Navigate replace to='/app' />} />
            </Routes>}
            {user.isLoading && <Warning type='loading' />}
            {user.isError && <Warning type='error' />}
            <Screen />
            <Modal />
        </div>
    </motion.div>
}

export default Lobby;