import { useEffect } from 'react';
import { useSocket } from '../contexts/SocketProvider';

const useSocketEvent = (name, callback) => {
    const socket = useSocket();

    useEffect(() => {
        socket?.on(name, callback);
        return () => socket?.off(name, callback);
    }, [socket, name, callback]);
}

export default useSocketEvent;