import '../styles/Settings.css';
import { useState } from 'react'
import { useMutation } from '@tanstack/react-query';
import { motion, AnimatePresence } from 'framer-motion';
import query from '../utils/query';
import { useUser } from '../contexts/UserProvider';
import ErrorMessage from './ErrorMessage';
import Button from './Button';

function Settings({ children, classNames, changes,
    isSaved = true, onSaveChangesSuccess }) {
    const user = useUser();
    const [error, setError] = useState(null);

    const userMutation = useMutation({
        mutationFn: user => query(`/users/@me`, {
            method: 'PATCH', body: user, isMultipart: true
        }),
        onSuccess: data => {
            user.setMe(() => data);
            onSaveChangesSuccess?.();
            setError(null);
        },
        onError: error => setError(error.message)
    });

    const isLoading = userMutation.isLoading;
    const isEditing = !isSaved || isLoading;
    const unsavedLabelText = 'You have unsaved changes!';
    const unsavedButtonText = 'Save';

    function handleSaveChanges() {
        setError(null);

        const formData = new FormData();

        for (const key in changes) {
            const value = changes[key];
            if (value) formData.append(key, value);
        }

        userMutation.mutate(formData);
    }

    classNames = 'settings__controls ' + (classNames ?? 'settings__default');

    return <motion.div
        className='settings'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
    >
        {error && <div className='settings__error'>
            <ErrorMessage error={error} />
        </div>}
        <div className='settings__content'>
            <div className={classNames}>
                {children}
            </div>
            {isEditing && <div className='settings__placeholder'>
                {unsavedLabelText}
                <Button content={unsavedButtonText} />
            </div>}
        </div>
        <AnimatePresence>
            {isEditing && <motion.div
                className='settings__save'
                initial={{ opacity: 0, y: 100 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 100 }}
            >
                {unsavedLabelText}
                <Button
                    content={unsavedButtonText}
                    isLoading={isLoading}
                    onClick={handleSaveChanges}
                />
            </motion.div>}
        </AnimatePresence>
    </motion.div>
}

export default Settings;