import '../styles/SignUp.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { ReactComponent as UsernameIcon } from '../assets/username.svg';
import { ReactComponent as EmailIcon } from '../assets/email.svg';
import { ReactComponent as LockIcon } from '../assets/lock.svg';
import getRegex from './../utils/getRegex';
import { useUser } from '../contexts/UserProvider';
import useGreeting from '../hooks/useGreeting';
import query from '../utils/query';
import FormInput from './FormInput';
import ErrorMessage from './ErrorMessage';
import Button from './Button';

function SignUp() {
    const { logIn } = useUser();
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const greeting = useGreeting();
    const usernameRegex = getRegex('username');

    const signupMutation = useMutation({
        mutationFn: signup => query('/auth/signup', {
            method: 'POST', body: { signup }
        }),
        onSuccess: async data => {
            await logIn(data.token);
            setIsLoading(false);
        },
        onError: error => {
            setError(error.message);
            setIsLoading(false);
        }
    });

    function handleSignUp(event) {
        event.preventDefault();
        setError(null);
        setIsLoading(true);
        const signup = { username, email, password };
        signupMutation.mutate(signup);
    }

    function handleUsernameChange(event) {
        const newUsername = event.target.value;
        if (newUsername && !newUsername.match(usernameRegex)) return;
        setUsername(newUsername);
    }

    function handleEmailChange(event) {
        setEmail(event.target.value);
    }

    function handlePasswordChange(event) {
        setPassword(event.target.value);
    }

    function handleLogInInstead() {
        navigate('/login');
    }

    return <motion.div
        className='signup'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
    >
        <form
            className='signup__form'
            onSubmit={handleSignUp}
        >
            <div className='signup__title'>
                {greeting}.
                <br></br>Sign up below. Chop, chop!
            </div>
            <div className='signup__inputs'>
                <FormInput
                    label='Username'
                    description='Username should contain only alphanumeric characters.'
                    placeholder='Choose a creative username.'
                    icon={<UsernameIcon />}
                    maxLength='40'
                    autoFocus={true}
                    value={username}
                    onChange={handleUsernameChange}
                />
                <FormInput
                    label='Email'
                    description='Email should be an email address... obviously.'
                    placeholder='Enter an email address...'
                    icon={<EmailIcon />}
                    value={email}
                    onChange={handleEmailChange}
                />
                <FormInput
                    label='Password'
                    description='Password should be 8-30 characters long.'
                    placeholder='Now, create a password!'
                    icon={<LockIcon />}
                    maxLength='30'
                    isHidden={true}
                    value={password}
                    onChange={handlePasswordChange}
                />
                <ErrorMessage error={error} />
            </div>
            <div className='signup__buttons'>
                <Button
                    content='Sign Up'
                    type='submit'
                    isLoading={isLoading}
                />
                <Button
                    content='Log In Instead'
                    model='secondary'
                    onClick={handleLogInInstead}
                />
            </div>
        </form>
        <div className='signup__banner'></div>
    </motion.div>
}

export default SignUp;