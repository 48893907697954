import '../styles/Content.css';
import { motion } from 'framer-motion';
import Lobby from './Lobby';

function Content() {
  return <main className='content'>
    <motion.div
      className='content__text'
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.4, delay: 1, type: 'spring' }}
    >
      Coming soon on a browser near you!
    </motion.div>
    <Lobby />
  </main>
}

export default Content;
