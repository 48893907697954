import '../styles/ImagePicker.css';

function ImagePicker({ label, imageURL, onChange }) {
    function handleImageChange(event) {
        onChange(event);
        event.target.value = '';
    }

    return <div className='image-picker'>
        {label}
        <div className='image-picker__content'>
            <label className='image-picker__upload'>
                <input
                    className='image-picker__upload-input'
                    type='file'
                    accept='image/*'
                    onChange={handleImageChange}
                />
                <div
                    className='image-picker__image'
                    style={{ backgroundImage: `url(${imageURL})` }}
                >
                </div>
            </label>
        </div>
    </div>
}

export default ImagePicker;