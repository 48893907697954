const getMs = timeString => {
    const timeObj = {
        milliseconds: { ms: 1, alias: ['ms', 'milliseconds', 'millisec', 'millisecond', 'millisecs', 'msec', 'msecs'] },
        seconds: { ms: 1000, alias: ['second', 'seconds', 'secs', 'sec', 's'] },
        minutes: { ms: 60000, alias: ['minute', 'minutes', 'min', 'mins', 'm'] },
        hours: { ms: 3600000, alias: ['hour', 'hours', 'h', 'hrs', 'hr'] },
        days: { ms: 86400000, alias: ['day', 'days', 'd'] },
        weeks: { ms: 604800000, alias: ['week', 'weeks', 'w', 'wk', 'wks'] },
        months: { ms: 2592000000, alias: ['month', 'months', 'mo', 'mth', 'mths'] },
        years: { ms: 31557600000, alias: ['year', 'years', 'yrs', 'yr', 'y'] }
    };

    timeString = timeString.toLowerCase();
    const times = timeString.match(/[0-9\.]+[a-z]+/gi);
    if (times?.join('') != timeString) return;

    let ms = 0;
    const units = [];
    for (let x in timeObj) units.push(...timeObj[x].alias);

    for (let x in times) {
        const timeUnit = times[x].match(/[0-9\.]+|[a-z]+/gi);

        if (!units.includes(timeUnit[1]) || isNaN(timeUnit[0])) return;

        for (let x in timeObj) {
            if (timeObj[x].alias.includes(timeUnit[1])) {
                ms += timeUnit[0] * timeObj[x].ms;
                break;
            }
        }
    }

    return ms;
}

export default getMs;