import '../styles/AppearanceSettings.css';
import { useState } from 'react'
import { useUser } from '../contexts/UserProvider';
import { useTheme } from '../contexts/ThemeProvider';
import Settings from './Settings';
import Toggle from './Toggle';
import Theme from './Theme';

function AppearanceSettings() {
    const user = useUser();
    const theme = useTheme();
    const LM = user.settings['LIGHT-MODE'] || false;
    const FM = user.settings['FULLSCREEN-MODE'] || false;
    const [isLMChecked, setIsLMChecked] = useState(LM);
    const [isFMChecked, setIsFMChecked] = useState(FM);
    const [primaryColor, setPrimaryColor] = useState(theme.primaryColor);
    const [secondaryColor, setSecondaryColor] = useState(theme.secondaryColor);
    const isSaved = LM === isLMChecked
        && FM === isFMChecked
        && theme.primaryColor === primaryColor
        && theme.secondaryColor === secondaryColor;

    const changes = {
        'LIGHT-MODE': isLMChecked?.toString(),
        'FULLSCREEN-MODE': isFMChecked?.toString(),
        'PRIMARY-COLOR': primaryColor,
        'SECONDARY-COLOR': secondaryColor
    };

    return <Settings
        changes={changes}
        isSaved={isSaved}
    >
        <Toggle
            label='Light Mode'
            description={'Even though dark mode is recommended, you can '
                + 'still enable retina-roasting radiation mode right here. '
                + 'You may also know it as light mode.'}
            isChecked={isLMChecked}
            setIsChecked={setIsLMChecked}
        />
        <Toggle
            label='Fullscreen Mode'
            description={'Instead of viewing the app in preview mode, '
                + 'you can toggle it into fullscreen mode for a better '
                + 'experience.'}
            isChecked={isFMChecked}
            setIsChecked={setIsFMChecked}
        />
        <Theme
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            setPrimaryColor={setPrimaryColor}
            setSecondaryColor={setSecondaryColor}
        />
    </Settings>
}

export default AppearanceSettings;