import { createContext, useContext, useState, useEffect } from 'react';
import io from 'socket.io-client';
import getBaseUrl from '../utils/getBaseUrl';
import { useUser } from './UserProvider';

const SocketContext = createContext();

export function useSocket() {
    return useContext(SocketContext);
}

function SocketProvider({ children }) {
    const [socket, setSocket] = useState();
    const baseUrl = getBaseUrl();
    const { token } = useUser();

    useEffect(() => {
        const newSocket = io(baseUrl, {
            path: '/ws/',
            auth: { token }
        });
        setSocket(newSocket);

        return () => newSocket.close();
    }, [baseUrl, token]);

    return <SocketContext.Provider value={socket}>
        {children}
    </SocketContext.Provider>
}

export default SocketProvider;