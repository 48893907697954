import '../styles/Messages.css';
import { useLayoutEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { decodeTime } from 'ulid';
import { ReactComponent as NoneIcon } from '../assets/none.svg';
import query from '../utils/query';
import Warning from './Warning';
import Message from './Message';

function Messages({ channel, receivedMessage }) {
  const messagesQuery = useQuery({
    queryKey: ['messages', channel.id],
    queryFn: () => query(`/channels/${channel.id}/messages`)
  });

  useLayoutEffect(() => {
    const hasFiles = receivedMessage.current?.files?.length;
    scrollToBottom(hasFiles ? 20000 : 2000);
  }, [receivedMessage.current]);

  useLayoutEffect(() => {
    scrollToBottom(-1);
  }, [channel.id, messagesQuery.isLoading]);

  function scrollToBottom(heightLimit) {
    const element = document.getElementById('chat-channel__messages');
    if (!element) return;

    const scrollHeightLeft = element.scrollHeight - element.scrollTop;
    const isScrollable = heightLimit === -1 || scrollHeightLeft < heightLimit;
    if (isScrollable) element.scrollTop = element.scrollHeight;
  }

  if (messagesQuery.isLoading) {
    return <Warning type='loading' />
  }

  if (messagesQuery.isError) {
    return <Warning
      type='error'
      text='Error loading messages...'
    />
  }

  const messages = messagesQuery.data.sort((a, b) =>
    a.id?.localeCompare(b.id) ?? 1
  );

  if (!messages.length) {
    return <Warning
      icon={<NoneIcon />}
      text='There are no messages in here yet...'
    />
  }

  return <ul className='messages'>
    <p className='messages__welcome'>
      Welcome to the beginning of {channel.name}...
    </p>
    {messages.map((message, index) => {
      const { id, nonce } = message;
      const previousMessage = messages[index - 1];
      const idOrNonce = id ?? nonce
      const previousIdOrNonce = previousMessage?.id ?? previousMessage?.nonce;

      const isNewSection = message.user.id !== previousMessage?.user.id
        || (decodeTime(idOrNonce) - decodeTime(previousIdOrNonce) > 300_000);

      return <li key={idOrNonce}>
        <Message
          message={message}
          isNewSection={isNewSection}
        />
      </li>
    })}
  </ul>
}

export default Messages;