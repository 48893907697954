import { createContext, useContext, useEffect } from 'react';
import { useUser } from './UserProvider';
import convertHslToHex from '../utils/convertHslToHex';

const ThemeContext = createContext();

export function useTheme() {
    return useContext(ThemeContext);
}

function ThemeProvider({ children }) {
    const user = useUser();
    const APC = user.settings['PRIMARY-COLOR'];
    const ASC = user.settings['SECONDARY-COLOR'];

    const computedDocStyle = getComputedStyle(document.documentElement);
    const primaryDefaultColorStyle = computedDocStyle.getPropertyValue('--primary-default-color');
    const secondaryDefaultColorStyle = computedDocStyle.getPropertyValue('--secondary-default-color');

    let primaryDefaultColor, secondaryDefaultColor;

    if (secondaryDefaultColorStyle.startsWith('hsl')) {
        primaryDefaultColor = convertHslToHex(...getHslValues(primaryDefaultColorStyle));
        secondaryDefaultColor = convertHslToHex(...getHslValues(secondaryDefaultColorStyle));
    } else {
        primaryDefaultColor = convertToLongHex(primaryDefaultColorStyle);
        secondaryDefaultColor = convertToLongHex(secondaryDefaultColorStyle);
    }

    const primaryColor = APC || primaryDefaultColor;
    const secondaryColor = ASC || secondaryDefaultColor;

    useEffect(() => {
        const docStyle = document.documentElement.style;
        docStyle.setProperty('--primary-color', primaryColor);
        docStyle.setProperty('--secondary-color', secondaryColor);
    }, [primaryColor, secondaryColor]);

    function getHslValues(hsl) {
        const hslValues = hsl.replace(/[^0-9,]*/g, '').split(',');
        return hslValues;
    }

    function convertToLongHex(hex) {
        const longHex = '#' + hex.replace('#', '')
            .split('').map(h => h + h).join('').toUpperCase();
        return longHex;
    }

    const theme = {
        primaryColor, secondaryColor,
        primaryDefaultColor, secondaryDefaultColor
    };

    return <ThemeContext.Provider value={theme}>
        {children}
    </ThemeContext.Provider>
}

export default ThemeProvider;