import Tooltip from '../components/Tooltip';

const formatDateTime = function (timestamp, placement) {
    const currDate = new Date();
    const targetDate = new Date(timestamp);

    let dateString = getDateString();
    if (placement) dateString = addDateTooltip(dateString);

    return dateString;

    function getDateString() {
        const isToday = getIsToday();
        const isYesterday = getIsYesterday();
        const { hours, minutes, ampm } = getTimeData();
        let day;

        if (isToday) {
            day = 'Today';
        } else if (isYesterday) {
            day = 'Yesterday';
        } else {
            const daysDiff = getDaysDiff();
            day = `${daysDiff} days ago`;
        }

        return `${day} at ${hours}:${minutes} ${ampm}`;
    }

    function addDateTooltip(dateString) {
        const tooltip = targetDate.toLocaleString();
        return <Tooltip content={tooltip} placement={placement}>
            <span>{dateString}</span>
        </Tooltip>;
    }

    function getIsToday() {
        return currDate.toDateString() === targetDate.toDateString();
    }

    function getIsYesterday() {
        const yesterday = new Date(currDate);
        yesterday.setDate(currDate.getDate() - 1);
        return yesterday.toDateString() === targetDate.toDateString();
    }

    function getTimeData() {
        const hours = targetDate.getHours() % 12 || 12;
        const minutes = targetDate.getMinutes().toString().padStart(2, '0');
        const ampm = targetDate.getHours() >= 12 ? 'PM' : 'AM';
        return { hours, minutes, ampm };
    }

    function getDaysDiff() {
        const MS_IN_ONE_DAY = 86_400_000;
        const msDiff = currDate - targetDate;
        return Math.ceil(msDiff / MS_IN_ONE_DAY);
    }
}

export default formatDateTime;