import '../styles/Tooltip.css';
import 'tippy.js/dist/tippy.css';
import Tippy from '@tippyjs/react';

function Tooltip({ children, content,
    offset = [0, 12], placement, disabled }) {
    return <Tippy
        className='tooltip tippy-box'
        content={content}
        offset={offset}
        placement={placement}
        hideOnClick={false}
        touch={['hold', 500]}
        disabled={disabled}
        theme='main-style'
        animation='main-style'
    >
        {children}
    </Tippy>
};

export default Tooltip;