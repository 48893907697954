import '../styles/JoinCommunity.css';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useUser } from '../contexts/UserProvider';
import { useModal } from '../contexts/ModalProvider';
import query from '../utils/query';
import Input from './Input';
import Button from './Button';

function JoinCommunity() {
    const user = useUser();
    const { closeModal } = useModal();
    const [joinText, setJoinText] = useState('');

    const membersMutation = useMutation({
        mutationFn: invite => query('/users/@me/communities', {
            method: 'POST', body: { invite }
        }),
        onSuccess: addCommunity
    });

    function handleJoinTextChange(event) {
        const newJoinText = event.target.value;
        setJoinText(newJoinText);
    }

    function handleJoinCommunity() {
        const invite = { code: joinText };
        membersMutation.mutate(invite);
        closeModal();
    }

    function addCommunity(community) {
        user.setCommunities((oldCommunities = []) => {
            const communityIsInCache = oldCommunities
                .some(c => c.id === community.id);

            return communityIsInCache
                ? oldCommunities
                : [community, ...oldCommunities];
        });
        user.setSelectedCommunity(community);
    }

    return <div className='join-community'>
        <div className='join-community__label'>
            Join a community with an invite, like <span>B0RG3R5</span>.
        </div>
        <div className='join-community__controls'>
            <Input
                placeholder='Enter a community invite...'
                maxLength='8'
                value={joinText}
                autoFocus={true}
                onChange={handleJoinTextChange}
            />
            <Button
                content='Join'
                disabled={!joinText}
                onClick={handleJoinCommunity}
            />
        </div>
    </div>
}

export default JoinCommunity;