import '../styles/Panels.css';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { useScreen } from '../contexts/ScreenProvider';
import { useModal } from '../contexts/ModalProvider';
import CommunitiesArea from './CommunitiesArea';
import NavigationArea from './NavigationArea';
import ChannelArea from './ChannelArea';
import CustomArea from './CustomArea';

function Panels() {
    const [selectedPanel, setSelectedPanel] = useState(1);
    const { pathname } = useLocation();
    const { isScreenClosed } = useScreen();
    const { isModalClosed } = useModal();

    const swipeableHandlers = useSwipeable({
        onSwipeStart: handleSwipe,
        trackMouse: true
    });

    function handleSwipe(swipe) {
        const direction = swipe.dir;
        if (!direction.match(/(Left|Right)/)) return;

        const sidePanel = (direction === 'Right') ? 0 : 2;
        const isNotSwipeable = getIsNotSwipeable(swipe, sidePanel);
        if (isNotSwipeable) return;

        const newSelectedPanel = (selectedPanel === 1) ? sidePanel : 1;
        setSelectedPanel(newSelectedPanel);
        blurActiveElement();
    }

    function getIsNotSwipeable(swipe, sidePanel) {
        const target = swipe.event.target;
        const isActiveElement = target === document.activeElement;
        const isOnInput = isActiveElement && target.nodeName === 'INPUT';
        const isOnTextarea = isActiveElement && target.nodeName === 'TEXTAREA';
        const isOnColorPicker = target.className.includes?.('react-colorful');
        const isOnCodeBlock = target.closest('.markdown>pre>code, .markdown>pre>pre');
        const isOnTable = target.closest('.markdown__table');
        const isOnSidePanel = selectedPanel === sidePanel;
        const isNotInApp = pathname !== '/app';
        const isNotSwipeable = isOnInput || isOnTextarea || isOnColorPicker
            || isOnCodeBlock || isOnTable || isOnSidePanel
            || isNotInApp || !isScreenClosed || !isModalClosed;
        return isNotSwipeable;
    }

    function blurActiveElement() {
        document.activeElement.blur();
    }

    return <div
        className='panels'
        style={{ '--selected-panel': selectedPanel }}
        {...swipeableHandlers}
    >
        <div
            className='panels__left'
            data-selected={selectedPanel === 0}
        >
            <CommunitiesArea />
            <NavigationArea setSelectedPanel={setSelectedPanel} />
        </div>
        <div
            className='panels__middle'
            data-selected={selectedPanel === 1}
        >
            <ChannelArea />
        </div>
        <div
            className='panels__right'
            data-selected={selectedPanel === 2}
        >
            <CustomArea />
        </div>
    </div>
}

export default Panels;