import '../styles/ColorPicker.css';
import { useState, useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
import { AnimatePresence, motion } from 'framer-motion';
import getRegex from './../utils/getRegex';
import useEvent from '../hooks/useEvent';
import FormInput from './FormInput';
import Tooltip from './Tooltip';

function ColorPicker({ label, color, onChange }) {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);
    const displayRef = useRef(null);
    const colorRegex = getRegex('hexColor');
    const isValidColorLength = color.length === 4 || color.length === 7;
    const displayColor = isValidColorLength ? color : color.padEnd(7, 0);

    useEvent('click', event => {
        const isButton = buttonRef.current?.contains(event.target);
        const isDisplay = displayRef.current?.contains(event.target);
        if (isButton || isDisplay) return;
        setIsOpen(false);
    });

    function handleColorInputChange(event) {
        let newColor = event.target.value.toUpperCase();
        if (!newColor.startsWith('#')) newColor = '#' + newColor;
        if (!newColor?.match(colorRegex)) return;
        onChange(newColor);
    }

    function handleColorPickerClick() {
        setIsOpen(oldIsOpen => !oldIsOpen);
    }

    function handleColorPickerChange(newColor) {
        newColor = newColor.toUpperCase();
        onChange(newColor);
    }

    return <div className='color-picker'>
        <div className='color-picker__input'>
            <FormInput
                label={label}
                description='The color should be presented as a hex code.'
                placeholder='#FF69FF'
                maxLength='7'
                value={color}
                style={{ paddingRight: '40px' }}
                onChange={handleColorInputChange}
            />
            <Tooltip content='Color Picker'>
                <button
                    ref={buttonRef}
                    className='color-picker__button'
                    style={{ backgroundColor: displayColor }}
                    onClick={handleColorPickerClick}
                >
                </button>
            </Tooltip>
        </div>
        <AnimatePresence>
            {isOpen && <motion.div
                ref={displayRef}
                className='color-picker__display'
                initial={{ opacity: 0, y: '150%' }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: '150%' }}
                transition={{ duration: 0.4, ease: [0.47, 1.64, 0.41, 0.8] }}
            >
                <div
                    className='color-picker__preview'
                    style={{ backgroundColor: displayColor }}
                >
                </div>
                <HexColorPicker
                    color={displayColor}
                    onChange={handleColorPickerChange}
                />
            </motion.div>}
        </AnimatePresence>
    </div>
}

export default ColorPicker;