import '../styles/Dropdown.css';
import { useState, useRef } from 'react';
import { ReactComponent as SettingsIcon } from '../assets/settings.svg';
import { useUser } from '../contexts/UserProvider';
import Button from './Button';

function Dropdown({ type, highlighted, label, icon,
    isDefaultOpen, onHeader, onSelect, options }) {
    const [isOpen, setIsOpen] = useState(isDefaultOpen);
    const [selectedOption, setSelectedOption] = useState(0);
    const listRef = useRef(null);

    function handleToggle(event) {
        event.stopPropagation();
        setIsOpen(oldIsOpen => !oldIsOpen);
    }

    function handleHeader() {
        onHeader();
    }

    function handleSelect(option, index) {
        onSelect(option, index);
        setSelectedOption(index);
    }

    let classNames = 'dropdown';
    classNames += isOpen ? ' dropdown--open' : '';
    classNames += highlighted ? ' dropdown--highlighted' : '';

    return <div className={classNames}>
        {type === 1 && <DropdownIconHeader
            icon={icon}
            handleToggle={handleToggle}
        />}
        {type === 2 && <DropdownChannelHeader
            label={label}
            icon={icon}
            handleHeader={handleHeader}
            handleToggle={handleToggle}
        />}
        {type === 3 && <DropdownSectionHeader
            label={label}
            icon={icon}
            handleHeader={handleHeader}
            handleToggle={handleToggle}
        />}
        <div
            className='dropdown__wrapper'
            style={{ height: isOpen ? listRef.current?.scrollHeight : 0 }}
        >
            {!!options?.length && <div className='dropdown__list' ref={listRef}>
                {options.map((option, index) => {
                    const isSelected = options.some(option => Object.hasOwn(option, 'isSelected'))
                        ? option.isSelected : selectedOption === index;
                    const props = { option, index, isSelected, handleSelect };

                    switch (type) {
                        case 1: return <DropdownIconItem key={index} {...props} />
                        case 2: return <DropdownChannelItem key={index} {...props} />
                    }
                })}
            </div>}
        </div>
    </div>
}

export default Dropdown;

function DropdownIconHeader({ icon, handleToggle }) {
    return <button
        className='dropdown__icon-header'
        onClick={handleToggle}
    >
        {icon}
    </button>
}

function DropdownIconItem({ option, index, isSelected, handleSelect }) {
    const { icon, tooltip, disabled } = option;

    return <Button
        content={icon}
        tooltip={tooltip.name}
        placement={tooltip.placement}
        isSelected={isSelected}
        disabled={disabled}
        styles={{ button: { margin: 'var(--padding-sm)' } }}
        onClick={() => handleSelect(option, index)}
    />
}

function DropdownChannelHeader({ label, icon, handleHeader, handleToggle }) {
    const user = useUser();
    const isOwner = user.member.permissions === "-1";

    return <div
        className='dropdown__channel-header'
        onClick={handleHeader}
        type='button'
    >
        <span className='dropdown__channel-label'>
            {icon} <span>{label}</span>
        </span>
        {isOwner && <button
            className='dropdown__channel-button'
            onClick={handleToggle}
        >
            <SettingsIcon />
        </button>}
    </div>
}

function DropdownChannelItem({ option, handleSelect }) {
    const { label, icon } = option;

    return <button
        className='dropdown__channel-item'
        onClick={() => handleSelect(option)}
    >
        {label} {icon}
    </button>
}

function DropdownSectionHeader({ label, icon, handleHeader }) {
    return <button
        className='dropdown__section-header'
        onClick={handleHeader}
    >
        {label} {icon}
    </button>
}