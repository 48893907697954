import '../styles/ChannelArea.css';
import { useUser } from '../contexts/UserProvider';
import useSectionTypes from '../hooks/useSectionTypes';
import useChannelTypes from '../hooks/useChannelTypes';
import Warning from './Warning';

function ChannelArea() {
    const { selectedSectionId, selectedTabId, selectedChannel } = useUser();
    const { selectedSectionType } = useSectionTypes();
    const { selectedChannelType } = useChannelTypes();

    const isASectionSelected = selectedSectionId !== null;

    const name = isASectionSelected
        ? selectedSectionType.tabs[selectedTabId].name
        : selectedChannel?.name;

    const component = isASectionSelected
        ? selectedSectionType.tabs[selectedTabId].component
        : (selectedChannelType?.component || <Warning type='loading' />);

    let classNames = 'channel-area ';
    classNames += isASectionSelected
        ? 'channel-area__tab'
        : 'channel-area__channel';

    return <div className={classNames}>
        <div className='channel-area__header'>
            {name}&nbsp;
        </div>
        <div className='channel-area__body'>
            <div className='channel-area__content'>
                {component}
            </div>
        </div>
    </div>
}

export default ChannelArea;