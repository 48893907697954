import '../styles/Modal.css';
import { useState, useRef, useEffect } from 'react';
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { ReactComponent as BackIcon } from '../assets/back.svg';
import { useModal } from '../contexts/ModalProvider';
import AddCommunity from './AddCommunity';
import JoinCommunity from './JoinCommunity';
import CreateCommunity from './CreateCommunity';
import CreateInvite from './CreateInvite';
import InvalidFileSize from './InvalidFileSize';
import LogOut from './LogOut';
import DeleteMessage from './DeleteMessage';
import Button from './Button';

function Modal() {
    const { modalId, isModalClosed, closeModal, revertModal, modalHistory } = useModal();
    const [contentHeight, setContentHeight] = useState(0);
    const contentRef = useRef(null);
    const footerRef = useRef(null);

    const modals = {
        'ADD_COMMUNITY': {
            name: 'Add Community',
            content: <AddCommunity />,
        },
        'JOIN_COMMUNITY': {
            name: 'Join Community',
            content: <JoinCommunity />,
        },
        'CREATE_COMMUNITY': {
            name: 'Create Community',
            content: <CreateCommunity />,
        },
        'CREATE_INVITE': {
            name: 'Create Invite',
            content: <CreateInvite />
        },
        'INVALID_FILE_SIZE': {
            name: 'Invalid File Size',
            content: <InvalidFileSize />
        },
        'LOG_OUT': {
            name: 'Log Out',
            content: <LogOut />
        },
        'DELETE_MESSAGE': {
            name: 'Delete Message',
            content: <DeleteMessage />
        }
    };

    const { name, content } = modals[modalId] || {};
    const isModalFlipped = modalHistory.length > 1;

    useEffect(() => {
        setContentHeight(contentRef.current?.scrollHeight);
    }, [content]);

    function handleClose() {
        setContentHeight(0);
        closeModal();
    }

    function handleBack() {
        revertModal();
    }

    let classNames = 'modal';
    if (isModalClosed) classNames += ' modal--closed';

    return <div className={classNames}>
        <div
            className='modal__backdrop'
            onClick={handleClose}
            role='presentation'
        >
        </div>
        <div className='modal__dialog'>
            <div className='modal__header'>
                {name}
                <Button
                    content={<CloseIcon />}
                    tooltip='Close'
                    onClick={handleClose}
                />
            </div>
            <div
                className='modal__wrapper'
                style={{ height: contentHeight }}
            >
                <div className='modal__content' ref={contentRef}>
                    {content}
                </div>
            </div>
            <div
                className='modal__wrapper'
                style={{
                    height: isModalFlipped ? footerRef.current?.scrollHeight : 0,
                    boxShadow: isModalFlipped ? 'var(--shadow)' : null,
                    opacity: isModalFlipped ? 1 : 0
                }}
            >
                <div className='modal__footer' ref={footerRef}>
                    <Button
                        content={<BackIcon />}
                        tooltip='Back'
                        placement='bottom'
                        onClick={handleBack}
                    />
                </div>
            </div>
        </div >
    </div >
}

export default Modal;