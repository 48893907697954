import '../styles/CommunityMembers.css';
import { useQueryClient } from '@tanstack/react-query';
import { useUser } from '../contexts/UserProvider';
import useSocketEvent from '../hooks/useSocketEvent';
import CommunityMembersPresence from './CommunityMembersPresence';
import Warning from './Warning';

function CommunityMembers() {
  const user = useUser();
  const queryClient = useQueryClient();

  useSocketEvent('update-members', member => {
    queryClient.setQueryData(
      ['members', member.community_id, user.token],
      oldMembers => {
        const currentMember = oldMembers
          .find(m => m.user.id === member.user.id);

        if (currentMember) return;

        const newMembers = [...oldMembers];
        newMembers.push(member);

        return newMembers;
      });
  });

  useSocketEvent('update-presence', presence => {
    queryClient.setQueryData(
      ['members', user.selectedCommunity.id, user.token],
      oldMembers => {
        const currentMember = oldMembers
          .find(m => m.user.id === presence.id);

        if (!currentMember) return;

        const newMembers = [...oldMembers]
          .filter(m => m.user.id !== presence.id);

        const newMember = { ...currentMember };
        const newUser = { ...newMember.user };

        newUser.presence = presence.status;
        newMember.user = newUser;
        newMembers.push(newMember);

        return newMembers;
      })
  });

  const members = user.members;
  const presentMembers = members.filter(m => m.user.presence === 1);
  const idleMembers = members.filter(m => m.user.presence === 2);
  const awayMembers = members.filter(m => !m.user.presence);

  return <div className='community-members'>
    {members.length
      ? <>
        <CommunityMembersPresence label='Present' members={presentMembers} />
        <CommunityMembersPresence label='Idle' members={idleMembers} />
        <CommunityMembersPresence label='Away' members={awayMembers} />
      </>
      : <Warning type='loading' />
    }
  </div>
}

export default CommunityMembers;