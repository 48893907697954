import '../styles/Warning.css';
import { ReactComponent as ErrorIcon } from '../assets/error.svg';
import { ReactComponent as LoadingIcon } from '../assets/loading.svg';

function Warning({ type, icon, color = 'var(--primary-color)', text }) {
    const warnings = {
        'error': { icon: <ErrorIcon />, color: 'var(--error-color)' },
        'loading': { icon: <LoadingIcon />, color: 'var(--primary-color)' }
    };

    const warning = warnings[type] ?? { icon, color };
    warning.text = text;

    return <div
        className='warning'
        style={{ color: warning.color }}
    >
        {warning.icon}
        {warning.text}
    </div>
}

export default Warning;