import '../styles/ChatChannel.css';
import { useState, useRef, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { ReactComponent as TypingIcon } from '../assets/typing.svg';
import getRegex from './../utils/getRegex';
import { useUser } from './../contexts/UserProvider';
import useSocketEvent from '../hooks/useSocketEvent';
import Messages from './Messages';
import MessageBox from './MessageBox';

function ChatChannel({ channel }) {
    const user = useUser();
    const queryClient = useQueryClient();
    const [typings, setTypings] = useState([]);
    const receivedMessage = useRef();
    const speech = new SpeechSynthesisUtterance();

    useSocketEvent('receive-message', async message => {
        if (message.channel_id !== channel.id) return;

        await loadImages(message);
        receivedMessage.current = message;

        const messages = queryClient.getQueryData(['messages', channel.id]);
        const isExistingUnsentMessage = messages.some(m =>
            m.isUnsent && m.nonce === message.nonce
        );

        isExistingUnsentMessage
            ? replaceUnsentMessage(message)
            : updateMessages(message);

        speakIfTTS(message);
    });

    useSocketEvent('receive-typing', typing => {
        if (typing.channelId !== channel.id) return;

        const isCurrentUser = t => t.user.id === user.id;
        const isTypingUser = t => t.user.id === typing.user.id;

        if (isCurrentUser(typing)) return;

        const initialTypingIndex = typings
            .findIndex(t => isTypingUser(t));

        const hasNoInitialTyping = initialTypingIndex === -1;

        const initialTyping = hasNoInitialTyping
            ? typing : typings[initialTypingIndex];

        initialTyping.count += typing.isTyping ? 1 : -1;

        const filteredTypings = typings
            .filter(t => !isTypingUser(t));

        if (initialTyping.count > 0 && !typing.isStopping) {
            const spliceTypingIndex = hasNoInitialTyping ? 0 : initialTypingIndex;
            filteredTypings.splice(spliceTypingIndex, 0, initialTyping);
        }

        setTypings(filteredTypings);
    });

    useEffect(() => {
        setTypings([]);
    }, [channel.id]);

    function updateMessages(message) {
        queryClient.setQueryData(
            ['messages', channel.id],
            oldMessages => [...oldMessages, message]
        );
    };

    function replaceUnsentMessage(message) {
        queryClient.setQueryData(
            ['messages', channel.id],
            oldMessages => {
                const oldMessagesCopy = [...oldMessages];
                const unsentMessageIndex = oldMessagesCopy
                    .map(m => m.nonce).indexOf(message.nonce);
                oldMessagesCopy.splice(unsentMessageIndex, 1, message);
                return oldMessagesCopy;
            }
        );
    };

    function loadImages(message) {
        const imageURLRegex = getRegex('imageURL');
        const globalImageURLRegex = new RegExp(imageURLRegex, 'g');
        const imageURLs = [
            ...(message.content.match(globalImageURLRegex) ?? []),
            ...message.files.map(({ url }) => url)
        ];

        const imageURLsPromises = imageURLs.map(async imageURL => {
            return await new Promise(resolve => {
                const image = new Image;
                image.onload = resolve;
                image.src = imageURL;
            });
        });

        const promise = Promise.all(imageURLsPromises);
        return promise;
    }

    function speakIfTTS(message) {
        const isTTSSettingDisabled = !user.settings['CHAT-CHANNEL-MESSAGE-TTS'];
        const isNotTTSMessage = !message.isTTS;

        if (isTTSSettingDisabled && isNotTTSMessage) return;

        speech.text = getSpeechText(message);
        window.speechSynthesis.speak(speech);
    }

    function getSpeechText(message) {
        const imageURLRegex = getRegex('imageURL');
        const youTubeURLRegex = getRegex('youTubeURL');
        const spotifyURLRegex = getRegex('spotifyURL');
        const anyURLRegex = getRegex('anyURL');
        const name = message.user.name;
        const content = message.content;

        switch (true) {
            case !!content.match(imageURLRegex):
                return `${name} sent an image link.`;
            case !!content.match(youTubeURLRegex):
                return `${name} sent a YouTube video.`;
            case !!content.match(spotifyURLRegex):
                return `${name} sent a Spotify link.`;
            case !!content.match(anyURLRegex):
                return `${name} sent a link.`;
            default:
                return `${name} says: ${content}`;
        }
    }

    return <motion.div
        className='chat-channel'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
    >
        <div
            className='chat-channel__messages'
            id='chat-channel__messages'
        >
            <Messages
                channel={channel}
                receivedMessage={receivedMessage}
            />
        </div>
        <div className='chat-channel__message-box'>
            <div
                className='chat-channel__typings'
                style={{ opacity: typings.length ? 1 : 0 }}
            >
                <TypingIcon />
                {typings.map(typing => {
                    const { id, name, color } = typing.user;

                    return <span
                        key={id}
                        className='chat-channel__typing'
                        style={{ color }}
                    >
                        {name}
                    </span>
                })}
            </div>
            <MessageBox
                channel={channel}
                receivedMessage={receivedMessage}
                updateMessages={updateMessages}
            />
        </div>
    </motion.div>
}

export default ChatChannel;