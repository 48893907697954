import '../styles/AccessibilitySettings.css';
import { useState } from 'react'
import { useUser } from '../contexts/UserProvider';
import Settings from './Settings';
import Toggle from './Toggle';

function AccessibilitySettings() {
    const user = useUser();
    const CCMT = user.settings['CHAT-CHANNEL-MESSAGE-TTS'];
    const [isCCMTChecked, setIsCCMTChecked] = useState(CCMT);
    const isSaved = CCMT === isCCMTChecked;

    const changes = {
        'CHAT-CHANNEL-MESSAGE-TTS': isCCMTChecked?.toString()
    };

    return <Settings
        changes={changes}
        isSaved={isSaved}
    >
        <Toggle
            label='Chat Channel Message TTS'
            description={'Enabling this will make all messages in '
                + 'an open Chat Channel be spoken out loud using '
                + 'text-to-speech.'}
            isChecked={isCCMTChecked}
            setIsChecked={setIsCCMTChecked}
        />
    </Settings>
}

export default AccessibilitySettings;