import { createContext, useContext, useState } from 'react';
import getMs from '../utils/getMs';

const ModalContext = createContext();

export function useModal() {
    return useContext(ModalContext);
}

function ModalProvider({ children }) {
    const [modalId, setModalId] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [modalHistory, setModalHistory] = useState([]);
    const [isModalClosed, setIsModalClosed] = useState(true);
    const transitionDuration = getMs(getComputedStyle(document.documentElement)
        .getPropertyValue('--transition-duration'));

    function openModal(newModalId, newModalData) {
        setModalId(newModalId);
        setModalData(newModalData);
        setModalHistory(oldModalHistory => [...oldModalHistory, newModalId]);
        setIsModalClosed(false);
    }

    function closeModal() {
        setIsModalClosed(true);
        setTimeout(() => {
            setModalId(null);
            setModalData(null);
            setModalHistory([]);
        }, transitionDuration);
    }

    function advanceModal(modalId) {
        setModalId(modalId);
        setModalHistory(oldModalHistory => [...oldModalHistory, modalId]);
    }

    function revertModal() {
        setModalId(modalHistory.at(-2));
        setModalHistory(oldModalHistory => oldModalHistory.slice(0, -1));
    }

    const modal = {
        modalId, modalData, modalHistory, isModalClosed,
        openModal, closeModal, advanceModal, revertModal
    };

    return <ModalContext.Provider value={modal}>
        {children}
    </ModalContext.Provider>
}

export default ModalProvider;