import '../styles/Footer.css';
import { useUser } from './../contexts/UserProvider';

function Footer() {
  const user = useUser();
  const year = new Date().getUTCFullYear();

  let classNames = 'footer';
  classNames += user.settings['LIGHT-MODE'] ? ' light-mode' : ' dark-mode';

  return <footer className={classNames}>
    <div className='footer__name'>
      Copyright &#169; {year} - TMK Media AS
    </div>
    <div className='footer__flag'></div>
  </footer>
}

export default Footer;