import { createContext, useContext, useState } from 'react';

const ScreenContext = createContext();

export function useScreen() {
    return useContext(ScreenContext);
}

function ScreenProvider({ children }) {
    const [screenId, setScreenId] = useState();
    const [isScreenClosed, setIsScreenClosed] = useState(true);

    function openScreen(newScreenId) {
        setScreenId(newScreenId);
        setIsScreenClosed(false);
    }

    function closeScreen() {
        setIsScreenClosed(true);
    }

    const screen = {
        screenId, isScreenClosed,
        openScreen, closeScreen
    };

    return <ScreenContext.Provider value={screen}>
        {children}
    </ScreenContext.Provider>
}

export default ScreenProvider;