import { createContext, useContext, useState, useEffect } from 'react';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { useLiveQuery } from 'dexie-react-hooks';
import indexedDB from '../services/indexedDB';
import query from '../utils/query';
import useLocalStorage from '../hooks/useLocalStorage';
import useEvent from '../hooks/useEvent';

const UserContext = createContext();

export function useUser() {
    return useContext(UserContext);
}

function UserProvider({ children }) {
    const [lastCommunityId, setLastCommunityId] = useLocalStorage('community');
    const [lastChannelId, setLastChannelId] = useLocalStorage('channel');
    const [selectedCommunityId, setSelectedCommunityId] = useState(lastCommunityId);
    const [selectedChannelId, setSelectedChannelId] = useState(lastChannelId);
    const initialSectionAndTab = lastCommunityId ? null : 0;
    const [selectedSectionId, setSelectedSectionId] = useState(initialSectionAndTab);
    const [selectedTabId, setSelectedTabId] = useState(initialSectionAndTab);
    const queryClient = useQueryClient();
    const tokenLiveQuery = useLiveQuery(() => indexedDB.storage
        .where('key').equals('token')?.first());
    const token = tokenLiveQuery?.value ?? null;
    const isLoggedIn = !!token;

    useEvent('load', () => isLoggedIn && savePushSubscription());
    useEvent('focus', () => isLoggedIn && updatePresence(1));
    useEvent('blur', () => isLoggedIn && updatePresence(2));
    useEffect(() => resetClientData(), [token]);

    const presenceMutation = useMutation({
        mutationFn: presence => query(`/users/@me/presence`, {
            method: 'PATCH', body: { presence }
        })
    });

    const meQuery = useQuery({
        queryKey: ['me', token],
        queryFn: () => query('/users/@me'),
        onError: error => {
            const errorRegex = /^(USER-TOKEN-INVALID|USER-ACCOUNT-NOT-FOUND)$/;
            if (error.message.match(errorRegex)) logOut();
        },
        enabled: isLoggedIn
    });

    const user = {
        ...meQuery.data, setMe,
        token, isLoggedIn, logIn, logOut,
        savePushSubscription, deletePushSubscription,
        setCommunities, setSelectedCommunity, setSelectedChannel,
        setSelectedSection, setSelectedTabId,
        selectedSectionId, selectedTabId
    };

    const communitiesQuery = useQuery({
        queryKey: ['communities', token],
        queryFn: () => query(`/users/@me/communities`),
        enabled: isLoggedIn
    });

    user.communities = communitiesQuery.data;
    user.selectedCommunity = findObject(user.communities, selectedCommunityId);

    const isUserReady = isLoggedIn
        && communitiesQuery.isSuccess
        && !!user.selectedCommunity.id;

    const channelsQuery = useQuery({
        queryKey: ['channels', user.selectedCommunity.id, token],
        queryFn: () => query(`/communities/${user.selectedCommunity.id}/channels`),
        enabled: isUserReady
    });

    user.channels = channelsQuery.data;
    user.selectedChannel = findObject(user.channels, selectedChannelId);

    const membersQuery = useQuery({
        queryKey: ['members', user.selectedCommunity.id, token],
        queryFn: () => query(`/communities/${user.selectedCommunity.id}/members`),
        enabled: isUserReady
    });

    user.members = membersQuery.data ?? [];
    user.member = user.members.find(m => m.user.id === user.id) ?? {};

    user.settings ??= {};

    for (const setting in user.settings) {
        const value = user.settings[setting];
        user.settings[setting] = value.match?.(/^(true|false)$/)
            ? (value === 'true') : value;
    }

    user.isLoading = isLoggedIn && (meQuery.isLoading || communitiesQuery.isLoading);
    user.isError = isLoggedIn && (meQuery.isError || communitiesQuery.isError);
    user.isSuccess = !user.isLoading && !user.isError;

    function updatePresence(status) {
        setTimeout(() => {
            const presence = { status };
            presenceMutation.mutate(presence);
        }, 2000);
    }

    function setMe(callback) {
        queryClient.setQueryData(['me', token], callback);
    }

    async function logIn(newToken) {
        await indexedDB.storage.put({ key: 'token', value: newToken });
        savePushSubscription();
    }

    async function logOut() {
        await deletePushSubscription();
        await indexedDB.storage.put({ key: 'token', value: null });
    }

    function savePushSubscription() {
        navigator.serviceWorker.controller?.postMessage({
            type: 'SAVE_PUSH_SUBSCRIPTION'
        });
    }

    async function deletePushSubscription() {
        try {
            const registration = await navigator.serviceWorker.getRegistration();
            const subscription = await registration?.pushManager.getSubscription();

            if (!subscription) return;

            await query(`/pushers`, { method: 'DELETE', body: { subscription } });
            await subscription.unsubscribe();
        } catch (error) {
            console.error(error);
        }
    }

    function setCommunities(callback) {
        queryClient.setQueryData(['communities', token], callback);
    }

    function setSelectedCommunity(communityResolvable) {
        const communityId = communityResolvable?.id || communityResolvable;
        setSelectedCommunityId(communityId);
        setLastCommunityId(communityId);
        setLastChannelId(null);
        setSelectedSectionId(null);
    }

    function setSelectedChannel(channelResolvable) {
        const channelId = channelResolvable?.id || channelResolvable;
        setSelectedChannelId(channelId);
        setLastChannelId(channelId);
    }

    function setSelectedSection(sectionId) {
        setSelectedSectionId(sectionId);
        if (sectionId === selectedSectionId) return;
        setSelectedTabId(0);
    }

    function resetClientData() {
        queryClient.removeQueries();
        for (const key in user) delete user[key];
        setLastCommunityId(null);
        setLastChannelId(null);
        setSelectedSectionId(0);
        setSelectedTabId(0);
    }

    function findObject(objects, id) {
        return objects?.find(o => o.id === id) || objects?.[0] || {};
    }

    return <UserContext.Provider value={user}>
        {children}
    </UserContext.Provider>
}

export default UserProvider;