import '../styles/ProfileSettings.css';
import { useState, useEffect } from 'react'
import getRegex from './../utils/getRegex';
import { useUser } from '../contexts/UserProvider';
import Settings from './Settings';
import FormInput from './FormInput';
import ColorPicker from './ColorPicker';
import ImagePicker from './ImagePicker';
import UserDisplay from './UserDisplay';

function ProfileSettings() {
    const user = useUser();
    const [name, setName] = useState(user.name);
    const [color, setColor] = useState(user.color);
    const [avatarURL, setAvatarURL] = useState(user.avatar_url);
    const [avatarFile, setAvatarFile] = useState(null);

    const isSaved = user.name === name
        && user.color === color
        && user.avatar_url === avatarURL;
    const nameRegex = getRegex('username');

    const changes = { name, color, avatar: avatarFile };

    useEffect(() => {
        setAvatarURL(user.avatar_url);
        setAvatarFile(null);
    }, [user.avatar_url])

    function handleSaveChangesSuccess() {
        setName(oldName => oldName.trim());
    }

    function handleNameChange(event) {
        const newName = event.target.value;
        if (newName && !newName.match(nameRegex)) return;
        setName(newName);
    }

    function handleColorChange(newColor) {
        setColor(newColor);
    }

    function handleAvatarChange(event) {
        const newAvatarFile = [...event.target.files][0];
        const newAvatarURL = URL.createObjectURL(newAvatarFile);
        setAvatarFile(newAvatarFile);
        setAvatarURL(newAvatarURL);
    }

    return <Settings
        classNames='profile-settings'
        changes={changes}
        isSaved={isSaved}
        onSaveChangesSuccess={handleSaveChangesSuccess}
    >
        <div className='profile-settings__options'>
            <FormInput
                label='Username'
                description='Username should contain only alphanumeric characters.'
                placeholder='Type out your new username.'
                maxLength='40'
                value={name}
                onChange={handleNameChange}
            />
            <ColorPicker
                label='Color'
                color={color}
                onChange={handleColorChange}
            />
            <ImagePicker
                label='Avatar'
                imageURL={avatarURL}
                onChange={handleAvatarChange}
            />
        </div>
        <div className='profile-settings__preview'>
            Preview
            {[1, 2, 3, 4, 5].map(number => <Preview
                key={number}
                user={{ name: name || user.name, color, avatar_url: avatarURL, tag: user.tag }}
                background={`var(--background-color-${number}`}
            />)}
        </div>
    </Settings>
}

export default ProfileSettings;

function Preview({ user, background }) {
    return <div
        className='profile-settings__preview-user'
        style={{ background }}
    >
        <UserDisplay
            user={user}
            bottom='This is what you would look like.'
        />
    </div>
}