import '../styles/AccountSettings.css';
import { ReactComponent as AccountIcon } from '../assets/account.svg';
import Settings from './Settings';
import Warning from './Warning';

function AccountSettings() {
    return <Settings classNames='account-settings'>
        <Warning
            icon={<AccountIcon />}
            text='There is nothing here yet...'
        />
    </Settings>
}

export default AccountSettings;