import '../styles/Userbar.css';
import { ReactComponent as LogOutIcon } from '../assets/log-out.svg';
import { useUser } from './../contexts/UserProvider';
import { useModal } from '../contexts/ModalProvider';
import { useScreen } from '../contexts/ScreenProvider';
import UserDisplay from './UserDisplay';
import Button from './Button';

function Userbar() {
    const user = useUser();
    const { openScreen } = useScreen();
    const { openModal } = useModal();

    function handleSecretPlace() {
        openScreen('SECRET_PLACE');
    }

    function handleLogOut() {
        openModal('LOG_OUT');
    }

    return <div className='userbar'>
        <UserDisplay
            user={user}
            onClick={handleSecretPlace}
        />
        <Button
            content={<LogOutIcon />}
            tooltip='Log Out'
            onClick={handleLogOut}
        />
    </div>
}

export default Userbar;