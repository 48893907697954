import '../styles/CreateInvite.css';
import { useState } from 'react';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import query from '../utils/query';
import { useUser } from '../contexts/UserProvider';
import Button from './Button';

function CreateInvite() {
    const user = useUser();
    const queryClient = useQueryClient();
    const [copy, setCopy] = useState('');
    const communityId = user.selectedCommunity.id;

    const inviteQuery = useQuery({
        queryKey: ['invites', communityId],
        queryFn: () => query(`/communities/${communityId}/invites`)
    });

    const inviteMutation = useMutation({
        mutationFn: () => query(`/communities/${communityId}/invites`, {
            method: 'POST', body: {}
        }),
        onSuccess: invite => queryClient.setQueryData(
            ['invites', communityId],
            () => invite
        )
    });

    const inviteCode = inviteQuery.data?.code;
    const isCopyFresh = inviteCode && copy === inviteCode;

    function handleCopyInvite() {
        navigator.clipboard.writeText(inviteCode);
        setCopy(inviteCode);
    }

    function handleGenerate() {
        inviteMutation.mutate();
    }

    return <div className='create-invite'>
        <div className='create-invite__label'>
            Generate and share your <span>new invite</span> below!
        </div>
        <div className='create-invite__code'>
            {inviteCode
                ? <span>{inviteCode}</span>
                : 'No invite generated yet...'}
        </div>
        <div className='create-invite__buttons'>
            <Button
                content={isCopyFresh ? 'Invite Copied' : 'Copy Invite'}
                model='secondary'
                isSelected={isCopyFresh}
                disabled={!inviteCode}
                onClick={handleCopyInvite}
            />
            <Button
                content='Generate'
                onClick={handleGenerate}
            />
        </div>
    </div>
}

export default CreateInvite;