import '../styles/Screen.css';
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { useScreen } from '../contexts/ScreenProvider';
import Button from './Button';

function Screen() {
    const { screenId, isScreenClosed, closeScreen } = useScreen();

    const screens = {
        'SECRET_PLACE': {
            name: 'h&65 #fl4*3d iH$dr3@',
            content: <div style={{ color: 'var(--error-color' }}>
                Please help............
            </div>
        }
    };

    const { name, content } = screens[screenId] || {};

    function handleClose() {
        closeScreen();
    }

    let classNames = 'screen';
    if (isScreenClosed) classNames += ' screen--closed';

    return <div className={classNames}>
        <div className='screen__content'>
            {content}
        </div>
        <div className='screen__footer'>
            <Button
                content={<CloseIcon />}
                tooltip='Close'
                onClick={handleClose}
            />
            {name}
        </div>
    </div>
}

export default Screen;