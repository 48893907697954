import indexedDB from '../services/indexedDB';
import getBaseUrl from './getBaseUrl';

const query = async (route, options = {}) => {
    const tokenQuery = await indexedDB.storage
        .where('key').equals('token')?.first();
    const token = tokenQuery?.value;

    const baseUrl = getBaseUrl();

    options.headers ??= {};
    options.headers.authorization = `Bearer ${token}`;

    if (!options.isMultipart) {
        options.headers['Content-Type'] ??= 'application/json';
        options.body = JSON.stringify(options.body);
    }

    const response = await fetch(baseUrl + route, options)
        .then(async res => {
            const data = await res.json();
            if (res.ok) return data;
            throw new Error(data.error);
        });

    return response;
}

export default query;