import { ReactComponent as ChatIcon } from '../assets/chat.svg';
import { ReactComponent as GalleryIcon } from '../assets/gallery.svg';
import { useUser } from '../contexts/UserProvider';
import ChatChannel from './../components/ChatChannel';
import GalleryChannel from './../components/GalleryChannel';

function useChannelTypes() {
    const { selectedChannel } = useUser();

    const channelTypes = {
        1: {
            icon: <ChatIcon />, tooltip: 'Chat Channel',
            component: <ChatChannel
                key={selectedChannel.id}
                channel={selectedChannel}
            />
        },
        2: {
            icon: <GalleryIcon />, tooltip: 'Gallery Channel',
            component: <GalleryChannel
                key={selectedChannel.id}
                channel={selectedChannel}
            />
        }
    };

    const selectedChannelType = channelTypes[selectedChannel?.type];

    return { channelTypes, selectedChannelType };
}

export default useChannelTypes;