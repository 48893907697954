import '../styles/Block.css';

function Block({ label, side, children }) {
  return <div className='block'>
    <div className='block__label'>
      <span className='block__name'>{label}</span>
      {side && <div>{side}</div>}
    </div>
    <div className='block__children'>
      {children}
    </div>
  </div>
}

export default Block;