import '../styles/DeleteMessage.css';
import { useModal } from '../contexts/ModalProvider';
import Message from './Message';
import Button from './Button';

function DeleteMessage() {
    const { closeModal, modalData } = useModal();
    const { message, deleteMessage } = modalData;

    function handleDeleteMessage() {
        deleteMessage();
        closeModal();
    }

    return <div className='delete-message'>
        <div className='delete-message__text'>
            Do you really want to <span>delete</span> this message?
        </div>
        <div className='delete-message__preview'>
            <Message
                message={message}
                isPreview={true}
            />
        </div>
        <div className='delete-message__button'>
            <Button
                content='Delete'
                onClick={handleDeleteMessage}
            />
        </div>
    </div>
}

export default DeleteMessage;