import '../styles/UserDisplay.css';
import UserAvatar from './UserAvatar';

function UserDisplay({ user, bottom, ...props }) {
    const { name, color } = user;
    bottom ??= `#${user.tag}`;

    return <div className='user-display'>
        <UserAvatar
            user={user}
            size='100%'
            {...props}
        />
        <div className='user-display__details'>
            <div style={{ color }}>{name}</div>
            <span>{bottom}</span>
        </div>
    </div>
}

export default UserDisplay;