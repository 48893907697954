import '../styles/InvalidFileSize.css';
import { useModal } from './../contexts/ModalProvider';
import Button from './Button';

function InvalidFileSize() {
    const { closeModal } = useModal();

    function handleFine() {
        closeModal();
    }

    return <div className='invalid-file-size'>
        <div className='invalid-file-size__text'>
            The files you're attaching are <span>too big</span>!
            <br></br>Keep it under <span>15 MB</span> per message, please.
        </div>
        <div className='invalid-file-size__button'>
            <Button
                content='But... FINE!'
                onClick={handleFine}
            />
        </div>
    </div>
}

export default InvalidFileSize;