import '../styles/App.css';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ScreenProvider from '../contexts/ScreenProvider';
import ModalProvider from '../contexts/ModalProvider';
import UserProvider from '../contexts/UserProvider';
import SocketProvider from '../contexts/SocketProvider';
import ThemeProvider from '../contexts/ThemeProvider';
import Navbar from './Navbar';
import Content from './Content';
import Footer from './Footer';

const queryClient = new QueryClient();

function App() {
  return <div className='app'>
    <BrowserRouter>
      <ScreenProvider>
        <ModalProvider>
          <QueryClientProvider client={queryClient}>
            <UserProvider>
              <SocketProvider>
                <ThemeProvider>
                  <Navbar />
                  <Content />
                  <Footer />
                </ThemeProvider>
              </SocketProvider>
            </UserProvider>
          </QueryClientProvider>
        </ModalProvider>
      </ScreenProvider>
    </BrowserRouter>
  </div>
}

export default App;