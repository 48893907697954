import '../styles/ProgressBar.css';

function ProgressBar({ length, percent, maxWidth }) {
    return <div className='progress-bar' style={{ maxWidth }}>
        <div className='progress-bar__label'>
            <span>
                Processing {length} File
                {(length === 1) ? '' : 's'}...
            </span>
            <span
                className='progress-bar__percentage'
                style={{ color: `hsl(${0 + percent}, 100%, 65%)` }}
            >
                {(percent === 100) ? 'Rendering' : `${percent}%`}
            </span>
        </div>
        <div className='progress-bar__bar'>
            <div
                className='progress-bar__progress'
                style={{ width: `${percent}%` }}
            >
            </div>
        </div>
    </div>
}

export default ProgressBar;