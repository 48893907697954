import '../styles/Theme.css';
import { useState } from 'react';
import { useTheme } from '../contexts/ThemeProvider';
import Tag from './Tag';
import ColorPicker from './ColorPicker';
import Button from './Button';
import Tooltip from './Tooltip';

function Theme({ primaryColor, secondaryColor,
    setPrimaryColor, setSecondaryColor }) {
    const [selectedTheme, setSelectedTheme] = useState(null);
    const { primaryDefaultColor, secondaryDefaultColor } = useTheme();

    const themes = [
        {
            name: 'Chatpex Default',
            colors: [primaryDefaultColor, secondaryDefaultColor]
        },
        { name: 'Soaring Pegasus', colors: ['#00B1CC', '#DCFBFF'] },
        { name: 'Sapphire Lion', colors: ['#FFFFFF', '#0038B8'] },
        { name: 'Sunny Sky', colors: ['#FFD700', '#00B7FF'] },
        { name: 'Electric Spark', colors: ['#7DF9FF', '#FF1493'] },
        { name: 'Heartfelt Love', colors: ['#FF9AFF', '#D30000'] },
        { name: 'Cherry Blossom', colors: ['#FFB7C5', '#DB7093'] },
        { name: 'Cotton Candy', colors: ['#FF88F1', '#22A2D1'] },
        { name: 'Twilight Haze', colors: ['#6A5ACD', '#FF69B4'] },
        { name: 'Dusky Rose', colors: ['#FF1493', '#5D4DC7'] },
        { name: 'Dragonfruit Paradise', colors: ['#FF00FF', '#7CFC00'] },
        { name: 'Tropical Storm', colors: ['#00FA9A', '#8B008B'] },
        { name: 'Moonlit Lavender', colors: ['#7B68EE', '#FFE4E1'] },
        { name: 'Midnight Sun', colors: ['#8A2BE2', '#FFD700'] },
        { name: 'Magic Theory', colors: ['#74D478', '#4056FF'] },
        { name: 'Ocean Sands', colors: ['#00BEAE', '#CDB599'] },
        { name: 'Frosted Pine', colors: ['#2E8B57', '#B0E0E6'] },
        { name: 'Melon Patch', colors: ['#77D474', '#DB5454'] },
        { name: 'Burning Ice', colors: ['#FF4500', '#00FFFF'] },
        { name: 'Exotic Delight', colors: ['#FF6347', '#FFD700'] },
        { name: 'Golden Hour', colors: ['#FFD700', '#9D5826'] },
        { name: 'Caramel Coffee', colors: ['#A07855', '#D4B996'] },
        { name: 'Rustic Meadow', colors: ['#845050', '#CFC2BC'] },
        { name: 'Rose Quartz', colors: ['#6E6E6D', '#FAD0C9'] }
    ];

    function handlePrimaryColorChange(color) {
        setPrimaryColor(color);
        setSelectedTheme(null);
    }

    function handleSecondaryColorChange(color) {
        setSecondaryColor(color);
        setSelectedTheme(null);
    }

    function handleThemeClick(theme) {
        setPrimaryColor(theme.colors[0]);
        setSecondaryColor(theme.colors[1]);
        setSelectedTheme(theme);
    }

    return <div className='theme'>
        <label className='theme__label'>
            <span>Custom Theme</span>
            <span className='theme__description'>
                Choose your own custom theme for the app.
                Use the color pickers or choose one of the presets below.
            </span>
        </label>
        <div className='theme__content'>
            <div className='theme__color-pickers'>
                <ColorPicker
                    label='Primary Color'
                    color={primaryColor}
                    onChange={handlePrimaryColorChange}
                />
                <ColorPicker
                    label='Secondary Color'
                    color={secondaryColor}
                    onChange={handleSecondaryColorChange}
                />
            </div>
            <div className='theme__list'>
                <label className='theme__list-label'>
                    <span>Theme Presets</span>
                    <span className='theme__list-description'>
                        Don't feel like picking colors
                        yourself? Choose a preset.
                    </span>
                </label>
                <div className='theme__buttons'>
                    {themes.map(theme => {
                        const isSelected = theme.name === selectedTheme?.name;

                        return <ThemeButton
                            key={theme.name}
                            theme={theme}
                            isSelected={isSelected}
                            onClick={() => handleThemeClick(theme)}
                        />
                    })}
                </div>
            </div>
            <div className='theme__list'>
                <div className='theme__list-header'>
                    <label className='theme__list-label'>
                        <span> Your Themes <Tag text='Coming' /></span>
                        <span className='theme__list-description'>
                            Create a unique new theme
                            and save it here for later use.
                        </span>
                    </label>
                    <Button
                        content='Save Theme'
                        disabled={true}
                    />
                </div>
                <div className='theme__buttons'>
                    <ThemeButton
                        theme={{
                            name: 'My Cool Theme',
                            colors: ['#A0C1D1', '#235C33']
                        }}
                        disabled={true}
                    />
                </div>
            </div>
        </div>
    </div>
}

export default Theme;

function ThemeButton({ theme, isSelected, disabled, onClick }) {
    const { name, colors } = theme;

    return <Tooltip content={name}>
        <button
            className='theme__button'
            style={{
                background: `linear-gradient(135deg, ${colors[0]}, ${colors[1]}, ${colors[0]}, ${colors[1]})`,
                borderRadius: isSelected ? '50%' : null
            }}
            disabled={disabled}
            onClick={onClick}
        >

        </button>
    </Tooltip>
}