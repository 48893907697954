import config from '../config.json';

const getBaseUrl = () => {
    const baseUrl = config.IS_PRODUCTION
        ? 'https://api.chatpex.com'
        : 'http://localhost:3000';

    return baseUrl;
}

export default getBaseUrl;