import { useState, useEffect } from 'react';

function useGreeting() {
    const [greeting, setGreeting] = useState('');

    useEffect(() => {
        const hour = new Date().getHours();
        const adjectives = ['Good', 'Excellent', 'Splendid',
            'Super', 'Have an awesome', 'Have a chatty'];
        const randomIndex = Math.floor((Math.random() * adjectives.length));
        let newGreeting = adjectives[randomIndex] + ' ';

        switch (true) {
            case (hour > 4 && hour < 12): newGreeting += 'morning'; break;
            case (hour > 11 && hour < 17): newGreeting += 'afternoon'; break;
            case (hour > 16 && hour < 21): newGreeting += 'evening'; break;
            case (hour > 20 || hour < 5): newGreeting += 'night'; break;
            default: newGreeting += 'day';
        }

        setGreeting(newGreeting);
    }, []);

    return greeting;
}

export default useGreeting;