import '../styles/Markdown.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

function Markdown({ text }) {
  return <ReactMarkdown
    className='markdown'
    remarkPlugins={[remarkGfm]}
    components={{
      img({ src, alt }) {
        return <>
          ![{alt}](<a href={src} target='_blank'>{src}</a>)
        </>
      },
      code({ className, children }) {
        const match = /language-(\w+)/.exec(className || '');
        const text = String(children).replace(/\n$/, '');

        return match
          ? <SyntaxHighlighter
            className='markdown__code'
            children={text}
            language={match[1]}
            showLineNumbers={true}
            style={vscDarkPlus}
          />
          : <code>{children}</code>
      },
      table({ children, ...args }) {
        return <div className='markdown__table'>
          <table {...args}>{children}</table>
        </div>
      },
      a({ children, ...args }) {
        return <a {...args} target='_blank'>{children}</a>
      }
    }}
  >
    {text}
  </ReactMarkdown>
}

export default Markdown;