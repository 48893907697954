import '../styles/LogOut.css';
import { useState } from 'react';
import { useUser } from '../contexts/UserProvider';
import { useModal } from '../contexts/ModalProvider';
import Button from './Button';

function LogOut() {
    const user = useUser();
    const { closeModal } = useModal();
    const [isLoading, setIsLoading] = useState(false);

    async function handleLogOut() {
        setIsLoading(true);
        await user.logOut();
        setIsLoading(false);
        closeModal();
    }

    return <div className='log-out'>
        <div className='log-out__text'>
            Are you sure you want to <span>log out</span>?
        </div>
        <div className='log-out__button'>
            <Button
                content='Sadly'
                isLoading={isLoading}
                onClick={handleLogOut}
            />
        </div>
    </div>
}

export default LogOut;