import '../styles/Updates.css';
import { motion } from 'framer-motion';
import Block from './Block';

function Updates() {
    const update120 = {
        name: 'Take A Picture', version: '1.2.0', date: 'December 25th, 2023', list: [
            'Updated the Overview to be more friendly to users who just onboarded.',
            'Added notifications so that you\'ll always know when someone needs you.',
            'Support for Markdown (CommonMark) has been added to Chat Channels.',
            'Syntax highlighting in codeblocks is now possible when specifying a language.',
            'Added the Gallery Channel for posting images with captions and spoilers.',
            'Improved typing of newlines in Chat Channels on your touch-only devices.',
            'The TTS in Chat Channels has been refined to detect different types of links.',
            'Sending cute messages is now doable with the Chat Channel\'s /uwu command.',
            'Communities now display their members (with statuses) on the right-side panel.',
            'Your device now knows the last channel you were in and greets you back into it.',
            'Added support for avatars for those of you whose names are not quite enough.',
            'Spotify link previews in Chat Channels now support more than only songs.',
            'Lastly, the user interface has been carefully touched up yet again.'
        ]
    };

    const update110 = {
        name: 'Let There Be Light', version: '1.1.0', date: 'June 17th, 2023', list: [
            'Sharing a Spotify link will now embed a preview of the song in Chat Channels.',
            'You can finally upload images to Chat Channels, plus more file types soon.',
            'Changing the default theme colors of the app is now possible in Settings.',
            'Added the ability to toggle both Light Mode and Fullscreen Mode.',
            'Slightly improved swiping between panels for your touch-only devices.',
            'When tapping on a channel/tab, you will now be automatically swiped to it.',
            'All messages can now be spoken out loud using TTS, if enabled in Settings.',
            'The modal has been given a nicer and more popping animation.',
            'Each channel/tab now has its own header with its name on it.',
            'The button for saving changes in Settings has been moved to the bottom.',
            'Added a log out confirmation for the accidental tappings of the Log Out button.',
            'As the very last new update, the user interface has been touched up a bit.'
        ]
    };

    const update100 = {
        name: 'New Beginning', version: '1.0.0', date: 'May 12th, 2023', list: [
            'The name and logo has been changed. Maybe they will again, but who knows?',
            'The default theme and styling has been changed for the better, too.',
            'User accounts have been implemented for consistency between devices.',
            'You can now create communities and invite your friends (and enemies).',
            'A Home section, which you\'re currently looking at, has been added.',
            'Changing of your username and color now happens in the Settings section.',
            'Sending memes is now ultra-easy with the Chat Channel\'s /meme command.',
            'Additionally, you can have your message be spoken with /tts (message).',
            'Finally, a few disabled buttons have been added for exciting future updates.'
        ]
    };

    const updates = [update120, update110, update100];

    return <motion.div
        className='updates'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
    >
        {updates.map((update, index) => {
            return <Block
                key={index}
                label={update.name}
                side={<div className='updates__details'>
                    <span>
                        <span className='updates__bold'>Version: </span>
                        {update.version}
                    </span>
                    <span>
                        <span className='updates__bold'>Date: </span>
                        {update.date}
                    </span>
                </div>}
            >
                <ul className='updates__list'>
                    {update.list.map((item, index) => {
                        return <li key={index}>{item}</li>
                    })}
                </ul>
            </Block>
        })}
    </motion.div>
}

export default Updates;