import '../styles/UserAvatar.css';

function UserAvatar({ user, size, borderRadius, ...props }) {
    user.border = false && `2px solid ${user.color}`;

    return <div
        className='user-avatar'
        style={{
            border: user.border,
            borderRadius,
            height: size
        }}
        {...props}
    >
        <div
            className='user-avatar__image'
            style={{ backgroundImage: `url(${user.avatar_url})` }}
        >
        </div>
    </div>
}

export default UserAvatar;