import '../styles/GalleryChannel.css';
import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ulid, decodeTime } from 'ulid';
import { motion } from 'framer-motion';
import { ReactComponent as DeleteIcon } from '../assets/delete.svg';
import { ReactComponent as SendIcon } from '../assets/send.svg';
import { ReactComponent as NoExhibitsIcon } from '../assets/no-exhibits.svg';
import { ReactComponent as VisibilityOnIcon } from '../assets/visibility-on.svg';
import query from '../utils/query';
import formatDateTime from '../utils/formatDateTime';
import getIsTouchOnlyDevice from '../utils/getIsTouchOnlyDevice';
import { useModal } from '../contexts/ModalProvider';
import useSocketEvent from '../hooks/useSocketEvent';
import ProgressBar from './ProgressBar';
import Button from './Button';
import FormInput from './FormInput';
import Toggle from './Toggle';
import Warning from './Warning';
import UserDisplay from './UserDisplay';

function GalleryChannel({ channel }) {
    const { openModal } = useModal();
    const queryClient = useQueryClient();
    const [uploadProgresses, setUploadProgresses] = useState([]);
    const [files, setFiles] = useState([]);
    const [captions, setCaptions] = useState({});
    const [spoilers, setSpoilers] = useState({});
    const [despoilers, setDespoilers] = useState({});

    const exhibitsMutation = useMutation({
        mutationFn: exhibits => query(`/channels/${channel.id}/exhibits`, {
            method: 'POST',
            body: exhibits,
            isMultipart: true
        })
    });

    useSocketEvent('receive-exhibits', async exhibits => {
        if (exhibits[0].channel_id !== channel.id) return;

        await loadImages(exhibits);
        updateExhibits(exhibits);
        removeUploadProgresses(exhibits);
    });

    useSocketEvent('receive-exhibit-upload-progress', async uploadProgress => {
        updateUploadProgresses(uploadProgress);
    });

    function handleFileUploadChange(event) {
        const newFiles = [...event.target.files];
        event.target.value = '';
        addFiles(newFiles);
    }

    function handleDeleteFile(nonce) {
        const newFiles = files.filter(file => file[2] !== nonce);
        setFiles(newFiles);
    }

    function handleCaptionChange(event, nonce) {
        const newCaptionValue = event.target.value;
        const newCaption = { [nonce]: newCaptionValue };
        const newCaptions = { ...captions, ...newCaption };
        setCaptions(newCaptions);
    }

    function handleSpoilerChange(newIsChecked, nonce) {
        const newSpoiler = { [nonce]: newIsChecked };
        const newSpoilers = { ...spoilers, ...newSpoiler };
        setSpoilers(newSpoilers);
    }

    function handleDespoilerChange(id) {
        const newDespoiler = { [id]: true };
        const newDespoilers = { ...despoilers, ...newDespoiler };
        setDespoilers(newDespoilers);
    }

    function handleSendExhibits() {
        createExhibits();
    }

    function loadImages(exhibits) {
        const imageURLs = exhibits.map(({ url }) => url);

        const imageURLsPromises = imageURLs.map(async imageURL => {
            return await new Promise(resolve => {
                const image = new Image;
                image.onload = () => resolve();
                image.src = imageURL;
            });
        });

        const promise = Promise.all(imageURLsPromises);
        return promise;
    }

    function updateExhibits(exhibits) {
        queryClient.setQueryData(
            ['exhibits', channel.id],
            oldExhibits => [...oldExhibits, ...exhibits]
        );
    };

    function removeUploadProgresses(exhibits) {
        const exhibitNonces = exhibits.map(e => e.nonce);
        const newUploadProgresses = uploadProgresses
            .filter(u => !exhibitNonces.includes(u.nonce));
        setUploadProgresses(newUploadProgresses);
    };

    function updateUploadProgresses(uploadProgress) {
        const uploadProgressIndex = uploadProgresses
            .findIndex(u => u.nonce === uploadProgress.nonce);

        if (!uploadProgressIndex) return;

        const newUploadProgresses = uploadProgresses
            .with(uploadProgressIndex, uploadProgress);

        setUploadProgresses(newUploadProgresses);
    };

    function addUploadProgresses(files) {
        const initialUploadProgresses = files
            .map(file => ({ nonce: file[2], filesSizeLoaded: 0 }));
        const newUploadProgresses = [...uploadProgresses, ...initialUploadProgresses];
        setUploadProgresses(newUploadProgresses);
    };

    function addFiles(newFiles) {
        if (!newFiles.length) return;

        newFiles = newFiles.map(file => {
            const fileURL = URL.createObjectURL(file);
            const nonce = ulid();
            return [file, fileURL, nonce];
        });

        const isInvalidFileSize = getIsInvalidFileSize(newFiles);
        if (isInvalidFileSize) return;

        setFiles(oldFiles => [...oldFiles, ...newFiles]);
    }

    function getIsInvalidFileSize(selectedFiles) {
        const filesSizeReducer = (a, b) => a + b[0].size;
        const filesSize = files.reduce(filesSizeReducer, 0);
        const selectedFilesSize = selectedFiles.reduce(filesSizeReducer, 0);
        const totalFilesSize = filesSize + selectedFilesSize;
        const maxFilesSize = 15_728_640;

        if (totalFilesSize > maxFilesSize) {
            openModal('INVALID_FILE_SIZE');
            return true;
        }
    }

    function createExhibits() {
        if (!files.length) return;

        setFiles([]);
        setCaptions({});
        setSpoilers({});

        const formData = new FormData();
        const meta = {};

        for (const file of files) {
            const [fileData, , nonce] = file;

            formData.append(nonce, fileData);

            meta[nonce] = {
                caption: captions[nonce] ?? '',
                isSpoilered: spoilers[nonce] ?? false
            };
        }

        formData.append('meta', JSON.stringify(meta));

        const exhibits = formData;
        exhibitsMutation.mutate(exhibits);
        addUploadProgresses(files);
    }

    const filesSizeLoadedTotalInaccurate = uploadProgresses
        .reduce((a, b) => a + b.filesSizeLoaded, 0) / uploadProgresses.length;
    const filesSizeLoadedTotal = Math.min(100,
        Math.round(filesSizeLoadedTotalInaccurate));

    return <motion.div
        className='gallery-channel'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4 }}
    >
        <Exhibits
            channel={channel}
            despoilers={despoilers}
            handleDespoilerChange={handleDespoilerChange}
        />
        {uploadProgresses[0] && <div className='gallery-channel__upload-progresses'>
            <ProgressBar
                length={uploadProgresses.length}
                percent={filesSizeLoadedTotal}
            />
        </div>}
        <div className='gallery-channel__preview-box'>
            {files[0] && <div className='gallery-channel__previews-border'>
                <div className='gallery-channel__previews'>
                    {files.map(([file, fileURL, nonce]) => {
                        const { name } = file;
                        const exhibit = { id: nonce, name, url: fileURL };
                        const isSpoilered = spoilers[nonce] ?? false;
                        const caption = captions[nonce] ?? '';

                        return <div
                            key={nonce}
                            className='gallery-channel__preview'
                        >
                            <div className='gallery-channel__preview-header'>
                                <span>{name}</span>
                                <Button
                                    content={<DeleteIcon />}
                                    tooltip='Delete'
                                    onClick={() => handleDeleteFile(nonce)}
                                />
                            </div>
                            <div className='gallery-channel__preview-body'>
                                <ExhibitFile
                                    exhibit={exhibit}
                                    isPreview={true}
                                    isSpoilered={isSpoilered}
                                    handleDespoilerChange={handleDespoilerChange}
                                />
                                <div className='gallery-channel__preview-details'>
                                    <FormInput
                                        label='Caption'
                                        description='Caption should be 200 characters or less.'
                                        placeholder='Describe your image...'
                                        maxLength='200'
                                        value={caption}
                                        onChange={event => handleCaptionChange(event, nonce)}
                                    />
                                    <Toggle
                                        label='Spoiler'
                                        description="Blur the image until it's pressed. Good for sensitive images."
                                        isChecked={isSpoilered}
                                        setIsChecked={newIsChecked => handleSpoilerChange(newIsChecked, nonce)}
                                    />
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>}
            <div className='gallery-channel__editor'>
                <label className='gallery-channel__upload'>
                    <input
                        className='gallery-channel__upload-input'
                        type='file'
                        accept='image/*'
                        multiple={true}
                        onChange={handleFileUploadChange}
                    />
                    Upload exhibits to {channel.name}...
                </label>
                <button
                    className='gallery-channel__send'
                    disabled={!files.length}
                    onClick={handleSendExhibits}
                >
                    <SendIcon />
                </button>
            </div>
        </div>
    </motion.div>
}

function Exhibits({ channel, despoilers, handleDespoilerChange }) {
    const exhibitsQuery = useQuery({
        queryKey: ['exhibits', channel.id],
        queryFn: () => query(`/channels/${channel.id}/exhibits`)
    });

    if (exhibitsQuery.isLoading) {
        return <Warning type='loading' />
    }

    if (exhibitsQuery.isError) {
        return <Warning
            type='error'
            text='Error loading exhibits...'
        />
    }

    const exhibits = exhibitsQuery.data.sort((a, b) =>
        b.id?.localeCompare(a.id) ?? 1
    );

    if (!exhibits.length) {
        return <Warning
            icon={<NoExhibitsIcon />}
            text='There are no exhibits in here yet...'
        />
    }

    return <div className='exhibits'>
        {exhibits.map(exhibit => {
            const { id, user, spoiler, caption } = exhibit;
            const timestamp = decodeTime(id);
            const date = formatDateTime(timestamp, 'bottom');
            const isSpoilered = !!spoiler && !despoilers[id];

            return <div
                key={id}
                className='exhibits__exhibit'
            >
                <UserDisplay
                    user={user}
                    bottom={date}
                />
                <ExhibitFile
                    exhibit={exhibit}
                    isPreview={false}
                    isSpoilered={isSpoilered}
                    handleDespoilerChange={handleDespoilerChange}
                />
                {caption && <div className='exhibits__details'>
                    {caption}
                </div>}
            </div>
        })}
    </div>
}

function ExhibitFile({ exhibit, isPreview, isSpoilered, handleDespoilerChange }) {
    const { id, name, url, width, height } = exhibit;
    const isTouchOnlyDevice = getIsTouchOnlyDevice();
    const pressType = isTouchOnlyDevice ? 'TAP' : 'CLICK';

    function handleImgError(event) {
        event.target.src = 'https://cdn.chatpex.com/assets/file-not-found.png';
        event.target.style = { aspectRatio: `1612/907` };
    }

    let classNames = 'exhibit-file';
    if (isPreview) classNames += ' exhibit-file--preview';
    if (isSpoilered) classNames += ' exhibit-file--spoilered';

    return <div className={classNames}>
        <img
            className='exhibit-file__embed'
            style={{ aspectRatio: `${width}/${height}` }}
            src={url}
            alt={name}
            draggable={false}
            lazy='true'
            onError={handleImgError}
        />
        <div
            className='exhibit-file__spoiler'
            onClick={() => handleDespoilerChange(id)}
        >
            <VisibilityOnIcon />
            <span>{`${pressType} TO VIEW`}</span>
        </div>
    </div>
}

export default GalleryChannel;